export interface LogoProps extends React.SVGAttributes<SVGSVGElement> {
}

export default function Logo({
  ...rest
}: LogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1114 470" fill="currentColor" {...rest}>
      <g>
        <path
          d="M264.8,228.7c25.9-16.7,42.7-33.2,51.4-35.9l-33.9-17c9.1-5.2,17.7-12.9,18-15l0.2-129.3c0,0-24.7,21.1-34.4,25.3 c0,0,0,45.4,0,51.6L215.3,124V81.1l43.9-13.5v-8.3c-42.5,13-33.1,14.3-77.8,7.2v145.8c0,1.2,0,3.1,0,4.4v67.2 c0,8.3-1.8,12.2-12.3,21.6l16.4,36c3.4-2.5,49.3-59.7,58.2-71.3l-0.5-2.8c-9.4,4.9-19,10.3-27.9,14.8v-95.7l16.2-4.9 c9.4,67.3,26,91.7,57.6,116l32.3-36.9l0.3-3.4C310,256.2,281.3,244.4,264.8,228.7z M215.3,178.1v-45.8l50.9-15.5v45.8L215.3,178.1 z M235.9,180.1l30.3-9.2v11.6c4.2-1.3,9.2-2.6,13-4.9c-4.2,11.1-12.3,29.9-20.1,45.1C249.2,211.4,241.1,197.2,235.9,180.1z" />
        <path
          d="M140.5,286.3v-54.7l34-10.4v-8.4l-34,10.4v-44.9c6.2-3.2,33.4-6.3,34.4-15.3l-21-13.9l-22.6,23.7L91.4,185 c10.7-17.2,20.3-35.9,27.7-54.2c6.2-3.7,61.2-13.9,61.3-23.5l-22.7-13L136.8,117l-14.1,4.3c3.4-8.8,10.9-35.9,10.9-35.9l-40.9,0.1 C91.4,116.8,83.3,174,71,209.6l2.1,0.8c5.7-7.1,11.5-14.8,16.7-23.2l1.6,6.1l13.6-4.1v44.9l-28.9,8.8v8.3l28.9-8.8v60.4 c0,6.5-2.3,10.2-16.2,26.6l33.3,23.3c0,0,48.6-81.1,57.3-98.3l-1-2.1C165.3,264.1,152.2,275.8,140.5,286.3z" />
        <polygon points="15.4,242.5 15.3,256.3 70.1,239.5 70.2,225.7" />
      </g>
      <g>
        <path
          d="M397.4,220.9l-3.3-0.1c-23,91.9-22.1,104.1-48.4,109.3v4.9c24-5.9,17.2,23.1,13.9,58.7l15.5,9.2l23.2-31.1 c0,0-10.4-29-11.1-44C389.5,291.8,397.4,220.9,397.4,220.9z" />
        <path d="M371.1,191c26.8,8.9,45.8-53.5-18.4-32.9l-1.5,1.9L371.1,191z" />
        <path d="M338.4,221.2l-1.5,1.6l15,31.8C377.1,266.7,400.1,205.5,338.4,221.2z" />
        <path
          d="M504.1,288.4V176.6c0,0-25.7,16.5-35.7,21.6c-3,1.5-12.8,3.9-12.8,3.9l-35.7-4.2v137.7l32.3-20.3V287l18.5-5.7v26.2 L504.1,288.4z M452.2,278.7v-67.2l18.5-5.7V273L452.2,278.7z" />
        <path
          d="M557.6,102c-5.9,4.3-22.8,21-35.8,26.9v196.3c0,3.9-1.6,6.7-6,8.1c-7.8,2.4-44.9,11.3-44.9,11.3v3.6 c15.1-2.2,36.2,4.4,38.9,32.7c9.9-3.7,11-5,11-5l38.3-60.3V125C559.1,103.3,557.6,102,557.6,102z" />
        <polygon points="413.3,168.2 413.3,176.6 512.1,146.3 512.1,138" />
      </g>
      <g>
        <path d="M615.7,67.1l-1.9,1.9l16.2,40.3C657.3,124.3,684.5,57.3,615.7,67.1z" />
        <path
          d="M659.3,138.1c-26.1,92.1-28.1,113.9-56.4,119.1v5.4c26.2-6.4,19.2,27.1,15.4,67.3l16.2,10.8l24.3-34.2 c0-0.1-11-34.2-11.8-51.5c2.2-40.6,14-95.9,16.5-117L659.3,138.1z" />
        <path d="M596.4,138.8l-1.9,1.9l13.9,38.6C634.2,194.7,662,129.8,596.4,138.8z" />
        <path
          d="M810.6,151.2v-41.3l26.9-8.2c3.4-1,6-3.3,6.8-6.8L828,86.3l-17.4,15.3V72.8c4.7-2.3,6-4.8,6.5-7.6l-37,7.4V111l-21.9,6.7 V88.9c4.7-2.3,6-4.8,6.5-7.6l-37,7.4v38.4c-14.1,5.1-11.8,0.1-11.9,12l11.9-3.6v53.8l30.5-17.9v-11.6l21.9-6.7v16.1L810.6,151.2z  M758.2,151.2v-25.3l21.9-6.7v25.3L758.2,151.2z" />
        <path
          d="M711.1,80.4L711.1,80.4c0.3,1.3-36.8-11.5-34.4-6.6c-0.7,63.7,1.4,264.2,2.1,294l32.1-22.2 C710.9,341.2,711.1,80.4,711.1,80.4z" />
        <path
          d="M848.2,40.8l-23.3-10.1c-2.5-0.1-22.9,22.9-24.8,22.3l-19.6,6c-1.5,0.4-2.6-1.5-1.6-2.8c12.2-15.7,6.1-40.6-39.3-28.4 c-0.8,0.7-1.4,1.9-0.7,2.9c5,7.5,8.9,19.9,8.8,32.9c0,0.9,0.6,1.5,1.4,1.9c1.3,0.7,1.1,2.9-0.3,3.3l-33.1,10.8v8 c32.4-12.2,133.8-37.3,133-44.5C849,42.3,848.8,41.3,848.2,40.8z" />
        <path
          d="M795.2,239.1c13-17.4,27.9-49.8,36.3-69.9l-21.4-8.9l-18.9,17.6c0,0-1,8.1-1.5,10.4c-2.5,12.4-12.3,31.1-21.1,45.8 c-14.6-5.3-26.6-14.5-35-29.2l48.6-14.8v-8.3l-65.7,20.1v8.3l12.2-3.8c5.7,22.3,14.1,38.5,24.8,50.4 c-12.1,16.2-26.4,31.5-42.5,45.8c-10,8.9-20.7,17.3-32.1,25.2l1.3,3.2c10.8-5.3,21.1-10.9,30.8-16.8c21.8-13.2,41-28,57.6-44.4 c14.9,9.5,32.9,14.1,54,16l27.9-46.3v-3.6C831.5,238.7,812.4,240.7,795.2,239.1z" />
      </g>
      <g>
        <path d="M928.4,213.3l-1.9,1.9l15.5,43.2C970.1,276.9,1000.7,202.8,928.4,213.3z" />
        <path d="M928.8,300.8l-1.9,1.9l16.9,47C972.9,368.5,1003.4,292,928.8,300.8z" />
        <path
          d="M1031,139.7c0,0-26,22.8-38,30.4v92.2l-79.3,24.2c0-3,0-78.7,0-83.8l70.4-21.5v-8.3l-43.8,13.4 c22.1-21.7,27.1-26.5,38.8-39.9l-46.4-2.7c-0.3,13.5-1.3,33.1-2.1,45.5l-11.5,3.5l-42.5-3.4c0,6.7,0,104.2,0,108.5L788,324.9v8.3 l88.4-27c-1,49.4-7.6,99.2-40.4,147.9l1.8,1.8c63.4-53.6,74.1-110.9,75.7-161.1l79.6-24.3v85.6c0,3.6-1,6-5.2,7.2 c-5.5,1.7-30,7.7-30,7.7v3.9c12.6-2.5,25.4,11.7,27.7,35.6c8.7-3.5,7.6-3.3,7.6-3.3L1031,349V139.7z" />
        <polygon points="1102,226.5 1038.4,246.1 1038.4,230.4 1102,210.8" />
      </g>
    </svg>
  );
}
